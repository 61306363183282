<template>
  <div class="upsell">
    <md-card md-with-hover>
      <md-card-header>
        <div class="md-title">
          We have so much more to do together...
        </div>
      </md-card-header>

      <md-card-content>
        <strong>Hey, {{ firstName }}!</strong> {{ practiceName }} is
        currently using the free version of Eye Got This online. <strong>Add
          payment information</strong> in order to unlock a bunch of great
        features including:
        <ul>
          <li>All 12 weeks of training material</li>
          <li>The ability to add trainees</li>
          <li>The ability to customize the training content</li>
        </ul>
      </md-card-content>

      <md-card-actions>
        <!-- <md-button
        class="md-raised md-accent">
          Add payment info
        </md-button> -->
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

const defaultContent = '';

export default {
  name: 'Upsell',
  props: {
    content: {
      type: String,
      default: () => defaultContent
    },
  },
  computed: mapState({
    user: (state) => {
      return state.user;
    },
    firstName: (state) => {
      const {user} = state;
      if (user && user.user_metadata && user.user_metadata.firstName) {
        return user.user_metadata.firstName;
      }
      return "Eye Got This trainer";
    },
    practiceName: (state) => {
      const {practice} = state;
      if (practice && practice.name) {
        return practice.name;
      }
      return "Your practice";
    }
  }),
  methods: {
    scrollToElement() {
      console.log(this.$refs);
      const el = this.$refs.scrollToMe;
      console.log(el);

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.upsell {
  display: flex;
}
</style>
