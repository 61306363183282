<template>
  <div>
    <h2>Account Information</h2>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{ dialogTitle }}</md-dialog-title>
      <md-dialog-content>
        <!-- TODO: style this -->
        <md-field>
          <label>{{ currentlyEditing }}</label>
          <md-input v-model="editedValue" />
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="cancelModal"
        >
          cancel
        </md-button>
        <md-button
          class="md-primary"
          @click="saveModal"
        >
          save
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-list class="md-double-line">
      <md-subheader>Personal Information</md-subheader>

      <md-list-item>
        <md-icon class="md-primary">
          email
        </md-icon>

        <div class="md-list-item-text">
          <span>{{ $auth.user.email }}</span>
          <span>Login</span>
        </div>
      </md-list-item>

      <md-list-item>
        <md-icon class="md-primary">
          account_circle
        </md-icon>

        <div class="md-list-item-text">
          <span>{{ firstName }}</span>
          <span>First Name</span>
        </div>

        <md-button
          class="md-icon-button md-list-action"
          @click="showEdit('name')"
        >
          <md-icon>edit</md-icon>
        </md-button>
      </md-list-item>

      <md-list-item class="md-inset">
        <div class="md-list-item-text">
          <span>**********</span>
          <span>Password</span>
        </div>

        <!-- <md-button class="md-icon-button md-list-action" @click="showDialog = true">
            <md-icon>edit</md-icon>
          </md-button> -->
      </md-list-item>

      <div>
        <md-divider />
        <!-- Payment Info -->
        <!-- Number of Licenses -->

        <div v-if="practice">
          <md-subheader>Practice Information</md-subheader>
          <md-list-item class="md-inset">
            <div class="md-list-item-text">
              <span>{{ practice.name }}</span>
              <span>Practice Name</span>
            </div>

            <md-button
              class="md-icon-button md-list-action"
              @click="showEdit('practiceName')"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </md-list-item>

          <md-list-item>
            <md-icon class="md-primary">
              payment
            </md-icon>

            <div class="md-list-item-text">
              <a>Show payment information</a>
            </div>

          <!-- <md-button class="md-icon-button md-list-action" @click="showDialog = true">
              <md-icon>edit</md-icon>
            </md-button> -->
          </md-list-item>
          <!-- TODO: Determine if we need/want this based on
          our licensing and subscription model. -->
          <!-- <md-list-item class="md-inset">
            <div class="md-list-item-text">
              <span>0</span>
              <span>Number of Licenses</span>
            </div>

            <md-button
              class="md-icon-button md-list-action"
              :disabled="true"
              @click="showDialog = true"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </md-list-item> -->
        </div>
        <div v-else>
          <md-empty-state
            md-icon="visibility"
            md-label="New Practice"
            md-description="Add your practice information"
          >
            <md-button
              class="md-primary md-raised"
              @click="showCreatePractice"
            >
              Add
            </md-button>
          </md-empty-state>
        </div>
      </div>
    </md-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { PracticesAPI } from '../api/practices';
import { UsersAPI } from '../api/users';

export default {
  name: "Account",
  data() {
    return {
      currentlyEditing: '',
      dialogTitle: '',
      editedValue: '',
      showDialog: false,
      status: '',
    };
  },
  computed: mapState({
    user: (state) => {
      return state.user;
    },
    firstName: (state) => {
      if (state && state.user && state.user.user_metadata && state.user.user_metadata) {
        const metadata = state.user.user_metadata;
        return metadata.firstName || '';
      }
      return '';
    },
    practice: (state) => {
      if (state && state.practice) {
        return state.practice || {};
      }
      return undefined;
    }
  }),
  methods: {
    cancelModal() {
      this.showDialog = false;
      this.currentlyEditing = '';
      this.editedValue = '';
    },
    async createPractice(name) {
      const accessToken = await this.$auth.getTokenSilently();
      const api = new PracticesAPI();
      try {
        const res = await api.Post(accessToken, { name });
        if (res) {
          this.$store.commit('SET_PRACTICE', res.data);
        }
        this.status = 'Success';
        this.cancelModal();
      } catch(err) {
        this.status = 'Failed';
      }
    },
    async patchPractice(name) {
      const accessToken = await this.$auth.getTokenSilently();
      const api = new PracticesAPI();
      try {
        const res = await api.Patch(accessToken, { name });
        if (res) {
          this.$store.commit('SET_PRACTICE', res.data);
        }
        this.status = 'Success';
        this.cancelModal();
      } catch(err) {
        this.status = 'Failed';
      }
    },
    async patchUser(attributes) {
      const accessToken = await this.$auth.getTokenSilently();
      const api = new UsersAPI();
      api.Patch(accessToken, attributes).then((res) => {
        console.log('result', res);
        if (res) {
          console.log(res.data);
          this.$store.commit('SET_USER', res.data);
        }
        this.status = 'Success';
        this.cancelModal();
      }).catch(() => {
        this.status = 'Failed';
      });
    },
    async saveModal() {
      switch (this.currentlyEditing) {
        case 'First Name':
          await this.patchUser({ firstName: this.editedValue });
          break;

        case 'New Practice Name':
          await this.createPractice(this.editedValue);
          break;

        case 'Practice Name':
          await this.patchPractice(this.editedValue);
          break;

        default:
          break;
      }

      console.log('you want to save', this.editedValue, 'for', this.currentlyEditing);
    },
    showCreatePractice() {
      this.dialogTitle = "Create new practice";
      // HACK: use a different name here so that we know to call POST instead of PATCH
      this.currentlyEditing = 'New Practice Name';
      this.showDialog = true;
    },
    showEdit(field) {
      this.dialogTitle = 'Update Information';
      this.showDialog = true;

      switch (field) {
        case 'name':
          this.currentlyEditing = 'First Name';
          break;

        case 'practiceName':
          this.currentlyEditing = 'Practice Name';
          break;

        default:
          break;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.md-accent {
  float: right;
}
.md-list-item {
  display: flex;
  max-width: fit-content;
}
</style>
