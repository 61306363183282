"strict on";

const auth0Audience = (process.env.VUE_APP_AUTH0_AUDIENCE || '').split(',');
const auth0ClientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const auth0Domain = process.env.VUE_APP_AUTH0_DOMAIN;
const welcomeDismissed = "egtWelcomeDismissed";

// hosted url is: "https://api.eyegotthis.online"
// local url is: "http://localhost:3000"
// TODO: fix this to just return what is stored in the env var
// and error if empty
function getURLBase() {
  if (process.env.API_URL_BASE) {
    return process.env.API_URL_BASE;
  }
  return process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://api.eyegotthis.online";
}

module.exports.API_URL_BASE = getURLBase();
module.exports.auth0 = {
  audience: auth0Audience,
  clientId: auth0ClientId,
  domain: auth0Domain,
};
module.exports.welcomeDismissed = welcomeDismissed
