<template>
  <div class="chapters">
    <h2>Sections</h2>
    <p>
      Read through each section below by week. Once you are comfortable with the
      content of each week, print out and complete the quiz for that week to
      test your knowledge. Your manager will need to grade the quiz and
      determine if you are ready to move on. Each quiz is a great learning
      opportunity to validate your knowledge and correct any mistakes. Make sure
      to have a good conversation with your manager about each week of training
      material.
    </p>
    <div
      v-if="loading"
      class="loading-overlay"
    >
      <md-progress-spinner
        md-mode="indeterminate"
        :md-stroke="2"
      />
    </div>
    <div
      v-for="chapter in chapters"
      :key="chapter._id"
    >
      <Chapter
        :title="chapter.name"
        :text="chapter.text"
        :week="chapter.week"
      />
    </div>
    <upsell v-if="!isCustomer" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Chapter from "./Chapter.vue";
import Upsell from "../Upsell.vue";

import { ChaptersAPI } from "../../api/chapters";

export default {
  name: "Chapters",
  components: {
    Chapter,
    Upsell,
  },
  data() {
    return {
      chapters: [],
      loading: false,
      show: false,
    };
  },
  computed: mapState({
    isCustomer: (state) => {
      const { practice } = state;
      if (practice && practice.customer === true) {
        return true;
      }
      return false;
    },
  }),
  mounted: function () {
    this.getChapters();
  },
  methods: {
    async getChapters() {
      const accessToken = await this.$auth.getTokenSilently();

      const api = new ChaptersAPI(accessToken);
      this.loading = true;
      api
        .Get(accessToken)
        .then((res) => {
          this.chapters = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.chapters = "Something's not right!";
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chapters {
  margin-right: 50px;
  .loading-overlay {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
