<template>
  <div class="md-title">
    <span>
      Eye Got This
    </span>

    <span
      v-if="title"
      class="md-subheading"
    >
      &dash; {{ title }}
    </span>
  </div>
</template>
<script>
export default {
  name: "PracticeTitle",
  props: {
    title: {
      type: String,
      default: () => undefined,
    }
  }
}
</script>
