'strict on';

import { BaseAPI } from './baseAPI';

class UsersAPI extends BaseAPI {
  constructor() {
    super('/api/users/self');
  }

  async Get(accessToken) {
    // console.log('getting token');
    // const token = await this.$auth.getTokenSilently();
    // console.log('done getting token');
    return this.instance.get(this.url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      return res;
    }).catch(err => {
      console.error('get user failed', err);
      //TODO: add error messaging here
    });
  }

  Patch(accessToken, attributes) {
    return this.instance.patch(this.url, attributes, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      return res;
    }).catch(err => {
      console.error('patch user failed', err);
      //TODO: add error messaging here
    });
  }
}

export { UsersAPI };
