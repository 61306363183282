"strict on";

import { API_URL_BASE } from './constants'
import axios from 'axios';
import router from '../router'

class ChaptersAPI {
  constructor() {
    this.url = new URL('/api/chapters', API_URL_BASE).toString();
    this.instance = axios.create({
      withCredentials: true,
      baseURL: this.url,
    });
  }

  Get(accessToken) {
    return this.instance.get(this.url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      return res;
    }).catch(err => {
      console.error(err);
      router.push('/');
    });
  }
}

export { ChaptersAPI };
