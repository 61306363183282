<template>
  <div>
    <md-button @click="show = !show">
      Week {{ week }} - {{ title }}
    </md-button>
    <div
      v-show="show"
      class="text"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: "Chapter",
  props: {
    title: {
      type: String,
      default: () => '',
    },
    text: {
      type: String,
      default: () => '',
    },
    week: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>
