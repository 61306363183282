"strict on";

// hosted url is: "https://api.eyegotthis.online"
// local url is: "http://localhost:3000"
function getURLBase() {
  if (process.env.API_URL_BASE) {
    return process.env.API_URL_BASE;
  }
  return process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://api.eyegotthis.online";
}

module.exports.API_URL_BASE = getURLBase();
