<template>
  <div class="page-container">
    <md-app
      md-waterfall
      md-mode="fixed"
    >
      <md-app-toolbar class="md-primary">
        <practice-title
          :title="practiceName"
          style="flex: 1"
        />
        <toolbar-menu />
      </md-app-toolbar>

      <md-app-drawer md-permanent="card">
        <md-list>
          <md-list-item to="/dashboard">
            <md-icon>home</md-icon>
            <span class="md-list-item-text">Home</span>
          </md-list-item>

          <md-list-item to="/sections">
            <md-icon>source</md-icon>
            <span class="md-list-item-text">Sections</span>
          </md-list-item>

          <md-list-item to="/quiz-answers">
            <md-icon>quiz</md-icon>
            <span class="md-list-item-text">Quizzes</span>
          </md-list-item>

          <md-list-item
            v-if="isTrainer"
            to="/ask-your-manager"
          >
            <md-icon>record_voice_over</md-icon>
            <span class="md-list-item-text">Ask Your Manager</span>
          </md-list-item>

          <md-list-item
            v-if="isTrainer"
            to="/users"
          >
            <md-icon>people_alt</md-icon>
            <span class="md-list-item-text">Users</span>
          </md-list-item>

          <md-list-item to="/account">
            <md-icon>account_circle</md-icon>
            <span class="md-list-item-text">Account</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content>
        <welcome-message />
        <router-view />
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PracticeTitle from "../PracticeTitle.vue";
import ToolbarMenu from "../ToolbarMenu.vue";
import WelcomeMessage from "../WelcomeMessage.vue";

export default {
  name: "Home",
  components: {
    PracticeTitle,
    ToolbarMenu,
    WelcomeMessage,
  },
  computed: mapState({
    isTrainer: (state) => {
      if (!state.user || !state.user.scopes) {
        return false;
      }
      return state.user.scopes.trainer;
    },
    practiceName: (state) => {
      const {practice} = state;
      if (practice && practice.name) {
        return practice.name;
      }
      return "";
    },
  }),
};
</script>

<style lang="scss" scoped>
.md-app {
  height: calc(100vh);
  max-height: calc(100vh);
  border: 1px solid rgba(#000, 0.12);
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}
</style>
