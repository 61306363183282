<template>
  <div>
    <h2>Quizzes</h2>
    <md-content>
      After you complete each week of training,
      <strong>download the quiz for that week and print it out</strong>. Then
      take the quiz and discuss the answers with your manager. The manager can
      then decide to mark that week of training complete so that you can move on
      to the next week of training.
    </md-content>

    <md-card
      v-for="week in quizWeeks"
      :key="week"
      md-with-hover
    >
      <md-ripple>
        <md-card-header>
          <div class="md-title">
            Download Quiz - Week {{ week }}
          </div>
          <div class="md-subhead">
            {{ getSubheading(week) }}
          </div>

          <md-card-actions>
            <md-button
              ref="weeks"
              class="md-raised md-primary"
              :disabled="getDownloadDisabled(week)"
              :href="getQuizLink(week)"
              target="_blank"
            >
              Download
            </md-button>
          </md-card-actions>
        </md-card-header>
      </md-ripple>
    </md-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QuizAnswers",
  data() {
    return {
      quizWeeks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  computed: mapState({
    isCustomer: (state) => {
      const { practice } = state;
      if (practice && practice.customer === true) {
        return true;
      }
      return false;
    },
  }),
  mounted: function () {
    return {};
  },
  methods: {
    getQuizLink(week) {
      return `./assets/quizzes/Week ${week} Quiz.pdf`;
    },
    getSubheading(week) {
      if (this.isCustomer) {
        return "";
      }
      return week === 1 || week === 2
        ? ""
        : "upgrade to a paid account to view";
    },
    getDownloadDisabled(week) {
      if (this.isCustomer) {
        return false;
      }
      return week === 1 || week === 2 ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card {
  width: 320px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}
</style>
