'strict on';

import { API_URL_BASE } from './constants'
import axios from 'axios';

class BaseAPI {
  constructor(path) {
    this.url = new URL(path, API_URL_BASE).toString();
    this.instance = axios.create({
      withCredentials: true,
      baseURL: this.url,
    });
  }
}

export { BaseAPI };
