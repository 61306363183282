<template>
  <div>
    <h2>Home</h2>
    <p>
      Welcome, {{ firstName }}! You have completed {{ percentComplete }}% of
      your Eye Got This training.
    </p>
    <h3>Progress Bar</h3>
    <p>
      If trainee, show my progress. If manager show number of active users and
      number of completed users.
    </p>
    <div class="progress">
      <md-progress-bar
        class="md-accent"
        md-mode="determinate"
        :md-value="percentComplete"
      />
      {{ percentComplete }}%
    </div>
    <h3>Detailed Progress</h3>
    <h4>My Progress</h4>
    <ul>
      <li>
        Answering Phones <md-icon style="padding-bottom: 6px">
          done
        </md-icon>
      </li>
      <li>
        Scheduling Appointments
        <md-icon style="padding-bottom: 6px">
          done
        </md-icon>
      </li>
      <li>Scheduling Words of Wisdom</li>
    </ul>
    <p>
      If trainee, show week by week done/not done. If manager, show each trainee
      and % progress. Click into each trainee/user to see week by week and mark
      weeks complete.
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StatusCodes } from "http-status-codes";
import { UsersAPI } from "../api/users";

export default {
  name: "Dashboard",
  // TODO: map the state values better here. Compute
  // firstName and percentComplete here instead of in the template
  computed: mapState({
    firstName: (state) => {
      if (
        state &&
        state.user &&
        state.user.user_metadata &&
        state.user.user_metadata
      ) {
        const metadata = state.user.user_metadata;
        return metadata.firstName || "";
      }
      return "";
    },
    percentComplete: (state) => {
      if (
        state &&
        state.user &&
        state.user.user_metadata &&
        state.user.user_metadata
      ) {
        const metadata = state.user.user_metadata;
        return metadata.percentComplete || 0;
      }
      return 0;
    },
  }),
  mounted: async function () {
    const accessToken = await this.$auth.getTokenSilently();
    const api = new UsersAPI();
    api
      .Get(accessToken)
      .then((res) => {
        if (res.status == StatusCodes.OK) {
          this.$store.commit("SET_USER", res.data);
          this.$store.commit("SET_PRACTICE", res.data.practice);
          this.$store.commit("SET_LOGGEDIN", this.$auth.loggedIn);

          if (window.localStorage) {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            window.localStorage.setItem(
              "practice",
              JSON.stringify(res.data.practice)
            );
          }
        }
      })
      .catch(() => {
        this.$store.commit("SET_USER", null);
        this.$store.commit("SET_PRACTICE", null);
        this.$store.commit("SET_LOGGEDIN", false);
        if (window.localStorage) {
          window.localStorage.setItem("loggedIn", false);
          window.localStorage.setItem("practice", null);
          window.localStorage.setItem("user", null);
        }
      });
  },
};
</script>

<style lang="scss" scoped>
.progress {
  max-width: 300px;
}
</style>
