'strict on';

import { BaseAPI } from './baseAPI';

class PracticesAPI extends BaseAPI {
  constructor() {
    super('/api/practices');
  }

  Post(accessToken, payload) {
    return this.instance.post(this.url, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      return res;
    }).catch(err => {
      console.error('patch user failed', err);
      //TODO: add error messaging here
    });
  }

  Patch(accessToken, payload) {
    return this.instance.patch(`${this.url}/self`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      return res;
    }).catch(err => {
      console.error('patch user failed', err);
      //TODO: add error messaging here
    });
  }
}

export { PracticesAPI };
