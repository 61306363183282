<template>
  <md-menu
    md-align-trigger
    md-direction="bottom-start"
  >
    <md-button
      md-menu-trigger
      class="md-icon-button"
    >
      <md-icon>more_vert</md-icon>
    </md-button>

    <md-menu-content>
      <md-menu-item>
        <md-button @click="logout">
          <md-icon>logout</md-icon>
          Logout
        </md-button>
      </md-menu-item>
    </md-menu-content>
  </md-menu>
</template>
<script>
export default {
  name: "ToolbarMenu",
  methods: {
    logout() {
      this.loading = true;

      console.log('logging out');
      console.log('auth', this.$auth);

      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
  },
}
</script>
