<template>
  <div class="welcome">
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Welcome</md-dialog-title>

      <md-dialog-content>
        Welcome to your 12-week training manual (one chapter per week)! So, I will start out by saying that there is absolutely no way for you to learn everything within 12 weeks. Sorry to burst your bubble right away. However, this manual will provide you with a great starting point. Of course, your manager will still need to go over quite a bit with you, and will need to make it clear to you how he/she wants this manual to be used. Perhaps your manager may prefer you go through the chapters in a different order, based on your office’s needs. Or perhaps there are additional insurances for you to learn, that are not included in this book. This is to be expected. Your manager should go over these details with you, as well as your defined role within the practice.
        <br><br>
        Every optometric practice is unique. Because of the differences from practice to practice, it is impossible to write a manual that works seamlessly for everyone. Nevertheless, this guide reflects my best effort to account for differences, indicating noteworthy differences with several stopping points in the book. These stopping points are indicated by the following icon, which suggests that you ask your manager a few questions, since I of course do not know all the ins and outs of your particular practice.
        <br><br><span style="align:center">🙋❓</span><br><br>
        You will also notice that at the end of each chapter, there is a quiz. After you take the quiz, find time for you and your manager to discuss your answers. This will be your opportunity to ask any questions you may have about that particular chapter as well. Your manager may then decide if, upon completion of the quiz, you are ready to use your knowledge practically within the office. For example, if you successfully pass the Chapter 7 quiz, you may be ready to start assisting patients with frame selection.
        <br><br>
        Since you’re coming into this position with no optical experience, the learning curve will be steep. You may feel overwhelmed at first. After several weeks, things should start to click. Over time, as you start to hone your sales skills, your manager should be able to see that your success is contributing to the practice’s success.
        <br><br>
        I sincerely hope you grow to love the eyecare field as much as I do. After all, what other field allows you to combine a knowledge of optics, physics, psychology, business, and fashion on a daily basis? And in a way that will allow you to improve the quality of life of another human being? Someone famously once said “If you love what you do, you’ll never have to work a day in your life.” I hope you find this to be the case, as I do.
        <br><br>
        Sincerely,<br>
        Neka Amato, O.D.<br>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="closeDialog"
        >
          Let's go!
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { welcomeDismissed } from '../constants'
export default {
  name: "WelcomeMessage",
  components: {},
  data() {
    return {
      showDialog: true,
    };
  },
  created() {
    if (localStorage.getItem(welcomeDismissed) === "true") {
      this.showDialog = false;
    }
  },
  methods: {
    closeDialog() {
      localStorage.setItem(welcomeDismissed, true);
      this.showDialog = false;
    },
  },
}
</script>