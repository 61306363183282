export default {
  SET_PRACTICE (state, practice) {
    state.practice = practice;
  },
  SET_USER (state, user) {
    state.user = user;
  },
  SET_LOGGEDIN (state, isLoggedIn) {
    state.loggedIn = isLoggedIn;
  },
}
