<template>
  <div>
    <h2>Users</h2>
    <i>SCOPES: trainer</i>
    <br>
    <p>
      This section is only for managers/trainers. Shows each trainee/user enrolled in
      training. Show "active" vs. "completed" users? Add a user from here?
      Mark each week for a user complete from here.
      <br>
      TODO:
      <ul>
        <li>data is FAKE upate to use real</li>
        <li>add user route</li>
        <li>Need to add customer/free logic</li>
        <li>Implement update progress action</li>
      </ul>
    </p>

    <div v-if="users.length === 0">
      <md-empty-state
        md-icon="person_add"
        md-label="Add your first user"
        md-description="You'll be able to help guide this person through the full training with details specific to your practice"
      >
        <md-button class="md-primary md-raised">
          Add first user
        </md-button>
      </md-empty-state>
    </div>

    <div
      v-else
      style="margin-bottom:10px"
    >
      <md-button class="md-raised md-primary">
        <md-icon>person_add</md-icon>
        Add User
      </md-button>
    </div>
    <div>
      <md-card
        v-for="cardUser in users"
        :key="cardUser._id"
        md-with-hover
      >
        <md-ripple>
          <md-card-header>
            <div class="md-title">
              {{ cardUser.firstName }}
              <span v-if="cardUser._id === user._id">(me)</span>
            </div>
            <div class="md-subhead">
              <md-progress-bar
                class="md-accent"
                md-mode="determinate"
                :md-value="cardUser.completed.percentDone"
              />
              {{ cardUser.completed.percentDone }}% done with training
            </div>
          </md-card-header>

          <md-card-content>
            {{ cardUser.completed.sections.length }} of {{ sections.length }} sections completed
          </md-card-content>

          <md-card-actions>
            <md-button class="md-raised md-primary">
              Update Progress
            </md-button>
          </md-card-actions>
        </md-ripple>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Users",
  data() {
    return {
      sections: [
        {},{},{},{},{},
        {},{},{},{},{},
        {},{},{},{},{},
        {},{},{},{},{},
        {},{},{},{},{},
      ],
      users: [
        {completed:{percentDone:10,sections:[{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"}]},scopes:{superadmin:false,trainer:true}, _id:"606947e6db7a728617e1131d",email:"test@test.com",updatedAt:"2021-11-08T05:43:53.908Z",practice:{_id:"606947e6db7a728617e1131c",name:"Private Eye Care",updatedAt:"2021-04-04T05:00:22.574Z",customer:false},firstName:"Timmy"},
        {completed:{percentDone:5, sections:[{name:"Scheduling Appointments"}]},scopes:{superadmin:false,trainer:false},_id:"606947e6db7a728617e1131",email:"test1@test.com",updatedAt:"2021-11-08T05:43:53.908Z",practice:{_id:"606947e6db7a728617e1131c",name:"Private Eye Care",updatedAt:"2021-04-04T05:00:22.574Z",customer:false},firstName:"Jimmy"},
        {completed:{percentDone:20,sections:[{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"},{name:"Scheduling Words of Wisdom"}]},scopes:{superadmin:false,trainer:false},_id:"606947e6db7a728617e113",email:"test2@test.com",updatedAt:"2021-11-08T05:43:53.908Z",practice:{_id:"606947e6db7a728617e1131c",name:"Private Eye Care",updatedAt:"2021-04-04T05:00:22.574Z",customer:false},firstName:"Brittany"},
        {completed:{percentDone:30,sections:[{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"},{name:"Scheduling Words of Wisdom"},{name:"Scheduling Words of Wisdom"}]},scopes:{superadmin:false,trainer:false},_id:"606947e6db7a728617e113",email:"test3@test.com",updatedAt:"2021-11-08T05:43:53.908Z",practice:{_id:"606947e6db7a728617e1131c",name:"Private Eye Care",updatedAt:"2021-04-04T05:00:22.574Z",customer:false},firstName:"Jennifer"},
        {completed:{percentDone:42,sections:[{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"},{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"},{name:"Scheduling Appointments"},{name:"Answering Phones"},{name:"Scheduling Words of Wisdom"}]},scopes:{superadmin:false,trainer:false},_id:"606947e6db7a728617e11",email:"test4@test.com",updatedAt:"2021-11-08T05:43:53.908Z",practice:{_id:"606947e6db7a728617e1131c",name:"Private Eye Care",updatedAt:"2021-04-04T05:00:22.574Z",customer:false},firstName:"Sam"},
      ],
      // users: [],
    };
  },
  computed: mapState({
    user: (state) => {
      return state.user;
  }
  }),
}
</script>

<style lang="scss" scoped>
  .md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
</style>
