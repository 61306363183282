import Vue from 'vue'
import VueRouter from 'vue-router'

import Account from '../components/Account.vue'
import AskYourManager from '../components/AskYourManager.vue'
import Chapters from '../components/views/Chapters.vue'
import Dashboard from '../components/Dashboard.vue'
import Home from '../components/views/Home.vue'
import NotFound from '../components/404.vue'
import QuizAnswers from '../components/QuizAnswers.vue'
import Users from '../components/Users.vue'

import { authGuard } from '../auth/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true },
    // beforeEnter: authGuard,
    children: [
      {
        path: '',
        redirect: '/dashboard', // default child path
      },
      {
        component: Account,
        meta: { requiresAuth: true },
        name: 'Account',
        path: '/account',
      },
      {
        component: AskYourManager,
        meta: { requiresAuth: true },
        name: 'Ask Your Manager',
        path: '/ask-your-manager',
      },
      {
        component: Dashboard,
        meta: { requiresAuth: true },
        name: 'Dashboard',
        path: '/dashboard',
      },
      {
        component: Chapters,
        meta: { requiresAuth: true },
        name: 'Sections',
        path: '/sections',
      },
      {
        component: QuizAnswers,
        meta: { requiresAuth: true },
        name: 'Quiz Answers',
        path: '/quiz-answers',
      },
      {
        component: Users,
        meta: { requiresAuth: true },
        name: 'Users',
        path: '/users',
      },
    ]
  },
  {
    // Not Found!
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior: function(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // eslint-disable-next-line
    authGuard(to, from, next);
  }
});

export default router
