import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default-dark.css";

import { auth0 } from "./constants";

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.use(VueMaterial);

Vue.use(Auth0Plugin, {
  domain: auth0.domain,
  clientId: auth0.clientId,
  audience: auth0.audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Check local storage to handle refreshes
if (window.localStorage) {
  const localUserString = window.localStorage.getItem('user') || 'null';
  const localUser = JSON.parse(localUserString);
  const practiceString = window.localStorage.getItem('practice') || 'null';
  const practice = JSON.parse(practiceString);

  if (localUser && store.state.user !== localUser) {
    store.commit('SET_USER', localUser);
  }

  if (practiceString && store.state.practice !== practice) {
    store.commit('SET_PRACTICE', practice);
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
