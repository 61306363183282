<template>
  <div>
    <h2>Ask Your Manager</h2>
    <i>SCOPES: trainer</i>
    <br>
    <i>Needs a new name - Customization, My Practice?</i>
    <p>
      Main area for practice customization. Will have questions for the manager to
      answer for each section. This information will then appear directly in the
      content for each section.
    </p>
    <p>
      In the future a practice will be able to pick what sections are used in
      the training.
    </p>
  </div>
</template>

<script>
export default {
  name: "AskYourManager",
}
</script>
