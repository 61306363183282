<template>
  <div id="notFound">
    <h1>You are lost.</h1>
    <h4>This page doesn't exist.</h4>
    <md-button to="/">
      Take me home
    </md-button>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>